@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none; }
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
  .deal {
    @apply grid grid-cols-1 gap-2 md:grid-cols-2; }
  .deal-item {
    @apply relative rounded-lg border border-gray-300 bg-white px-2 py-2 shadow-sm flex flex-col flex-none items-center text-sm font-medium text-gray-900; }
  .deal-item img {
    @apply rounded; } }
