@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 6px #000000; }
  .message-container {
    @apply flex items-end; }
  .message-area {
    @apply flex flex-col space-y-1 text-sm max-w-md mx-2 order-2 items-start; }
  .message-text {
    @apply px-4 py-2 rounded-xl inline-block rounded-bl-none bg-white text-gray-800; }
  .message-avatar {
    @apply w-6 h-6 rounded-full order-1; }
  .message-image img {
    @apply rounded-lg my-2; }
  .message-image-no-rounded img {
    @apply my-2; }
  .response-container {
    @apply flex items-end justify-end; }
  .response-area {
    @apply flex flex-col space-y-1 text-sm max-w-xs sm:max-w-md mx-2 order-1 items-end; }
  .response-text {
    @apply px-4 py-2 rounded-xl inline-block rounded-br-none bg-blue-400 text-white; }
  .chained {
    @apply rounded-xl; }
  .choice-container {
    @apply flex items-end justify-end; }
  .choice-area {
    @apply flex flex-col space-y-4 bg-gradient-to-br from-blue-400 to-blue-600 rounded-xl rounded-br-none p-4 text-white w-full max-w-xl items-end; }
  .choice-multiple-choice {
    @apply w-full; }
  .choice-multiple-choice ul {
    @apply flex flex-col space-y-2 text-sm; }
  .choice-multiple-choice ul li button {
    @apply block rounded-lg bg-white bg-opacity-90 w-full text-left p-2 px-4 text-gray-800 transition duration-200 ease-in-out hover:bg-opacity-100; }
  .input-error {
    @apply border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500; }
  .input-error-hint {
    @apply inline-block mt-2 text-sm text-red-600; }
  /* Typing indicator */
  .ti-ellipsis {
    @apply items-center flex h-5 space-x-1; }
  .ti-ellipsis .ti-dot {
    @apply bg-gray-300; }
  .ti-dot {
    @apply animate-bounce rounded-full h-2 w-2;
    /* animation: mercuryTypingAnimation 1.5s infinite ease-in-out; */
    /* border-radius: 2px; */
    /* display: inline-block; */
    /* height: 4px; */
    /* margin-right: 2px; */
    /* width: 4px; */ }
  .ti-dot:nth-child(1) {
    animation-delay: 200ms; }
  .ti-dot:nth-child(2) {
    animation-delay: 300ms; }
  .ti-dot:nth-child(3) {
    animation-delay: 400ms; }
  .discover-frame {
    @apply shadow-md rounded-xl relative; }
  .featured {
    @apply absolute p-4 text-white drop-shadow-md font-semibold; }
  .featured h3 {
    @apply text-3xl block; }
  .recommendations {
    @apply shadow-md rounded-xl relative w-full bg-white cursor-pointer overflow-hidden;
    padding-bottom: 100%; }
  .rec-frame {
    @apply z-10 absolute p-4 text-white font-semibold h-full w-full; }
  .rec-frame h3 {
    @apply text-base md:text-xl block; }
  .rec-options {
    @apply grid grid-cols-2 absolute inset-x-0 bottom-0 w-full h-14 md:h-16 xl:h-20; }
  .rec-button {
    @apply text-center; }
  .rec-button button {
    @apply bg-opacity-50 hover:bg-opacity-100 transition rounded-full h-10 w-10 md:h-14 md:w-14 m-auto border-2 border-white; }
  .rec-button svg {
    @apply h-2/3 w-2/3 m-auto; }
  .rec-image {
    @apply rounded-xl group-hover:opacity-70 duration-200 absolute w-full; }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none; }
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
  body {
    top: 0px !important; } }
