@tailwind base;
@layer utilities {
  .aooch-choice {
    @apply max-w-full items-start overflow-x-scroll rounded-xl; }
  .aooch-choice > span {
    @apply font-bold text-sm opacity-90; }
  .aooch-choice ul {
    @apply flex gap-2 pr-6 md:pr-0; }
  .aooch-choice ul li {
    @apply bg-white rounded-md bg-opacity-90 hover:bg-opacity-80 transition cursor-pointer w-40 h-40 font-semibold; }
  .aooch-choice ul li.narrow {
    @apply bg-black bg-opacity-50 border-b-4 hover:bg-opacity-70 hover:border-white text-base p-4; }
  .aooch-choice ul li.toggled {
    @apply text-green-300 border-green-400; } }

@tailwind components;
@tailwind utilities;
