@tailwind base;
@tailwind components;
@layer utilities {
  .gallery-ul {
    @apply grid grid-cols-3 gap-0.5 sm:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8; }
  .gallery-item {
    @apply block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden; }
  .gallery-item img {
    @apply object-cover; }
  .gallery-overlay {
    @apply absolute z-50; }
  .gallery-overlay span {
    @apply absolute bottom-2 right-2 text-white text-xs select-none drop-shadow; } }

@tailwind utilities;
