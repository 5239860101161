@tailwind base;
@layer utilities {
  .aooch-notification {
    @apply text-center my-4 flex self-center justify-center; }
  .aooch-notification p {
    @apply bg-white bg-opacity-50 text-blue-500 text-xs p-2 px-4 rounded-full flex gap-2; }
  .aooch-notification p svg {
    @apply h-4 w-4; } }

@tailwind components;
@tailwind utilities;
