@tailwind base;
@tailwind components;
@layer utilities {
  /* TODO: These are new 2023-05-03 */
  .searchbar {
    @apply relative w-full px-4 pt-5; }
  .searchbar input#search {
    @apply w-full rounded-full border-0 px-5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600; }
  .searchbar .magnifying-glass-icon {
    @apply pointer-events-none absolute right-8 top-7 h-6 w-6 transition-all;
    fill: #6b7280; }
  .explore {
    @apply gap-1 mt-5;
    columns: 2;
    margin-bottom: 48px; }
  @media (min-width: 768px) {
    .explore {
      columns: 3;
      margin-bottom: 0px; } }
  @media (min-width: 1280px) {
    .explore {
      columns: 4; } }
  .explore img, .explore video {
    @apply h-auto w-full mb-1; }
  /* BELOW COMES FROM OLD BROWSE CSS, NOT CHANGED */
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none; }
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
  .browse {
    @apply grid grid-cols-1 gap-2 md:grid-cols-2; }
  .browse-item {
    @apply relative rounded-lg border border-gray-300 bg-white px-2 py-2 shadow-sm flex items-center space-x-3 text-sm font-medium text-gray-900; }
  .browse-avatar {
    @apply flex-shrink-0; }
  .browse-avatar-lg img {
    @apply h-12 w-12 rounded-full; }
  .browse-avatar img {
    @apply h-8 w-8 rounded-full; }
  .browse-link,
  .browse-link a {
    @apply flex-1 block; }
  .browse-filter {
    @apply pt-20 sm:pt-20 lg:pt-6; }
  .browse-filter-tags {
    @apply flex overflow-scroll px-4 space-x-2 md:px-10; }
  .browse-filter-tags li {
    @apply flex-none relative bg-gray-200 border-gray-300 border text-xs px-3 py-1 rounded-full; }
  .browse-filter-tags li.active {
    @apply border-blue-700 bg-blue-600 text-white; }
  /* TODO: This was changed 2023-05-03 to fix a positioning error */
  .tags-arrow {
    @apply hidden md:block absolute z-50  text-xs py-1 px-1 border-gray-600 border bg-gray-600 hover:bg-black cursor-pointer rounded-full; }
  .tags-left {
    @apply left-4; }
  .tags-right {
    @apply right-4; }
  .tags-arrow svg {
    @apply h-4 w-4 text-white; } }

@tailwind utilities;
